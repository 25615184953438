import axios, { CancelTokenSource } from "axios";
import { LSKEY_TOKEN } from "../constants/app";
import ls from "../utils/ls";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const cancelSources: CancelTokenSource[] = [];

axios.interceptors.request.use(
  (config) => {
    const cfg = config;
    const isHeaderSet = cfg.headers.Authorization;
    if (!isHeaderSet) {
      const token = ls.get(LSKEY_TOKEN);
      const bearer = `Bearer ${token}`;
      if (token) cfg.headers.Authorization = bearer;
    }

    const cs = axios.CancelToken.source();
    cancelSources.push(cs);

    cfg.cancelToken = cs.token;

    return cfg;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response.data)
);

const cancelAll = (): void => {
  cancelSources.forEach((source) => source.cancel("Request cancelled"));
};

const { get, post, put, patch, request, isCancel } = axios;

const http = { get, post, put, patch, cancelAll, isCancel, request };

export default http;

import { z as zod } from "zod";

export const UpdateDetailsFormSchemas = zod
  .object({
    token: zod.string(),
    firstName: zod.string().min(1, "First name is required").max(30),
    lastName: zod.string().min(1, "Last name is required").max(30),
    mobileNumber: zod.string().min(1, "Phone or Mobile is required").max(13),
    region: zod.string().optional(),
    preferredStore: zod.string().optional(),
    emailAddress: zod
      .string()
      .min(1, "Email is required")
      .email("The Email Address field is not a valid e-mail address."),
    // birthdayDD: zod.string().optional(),
    // birthdayMM: zod.string().optional(),
    password: zod.string().optional(),
    confirmPassword: zod.string().optional(),
    IsNewsUpdatesEmailsEnabled: zod.boolean().optional(),
    IsTextEnabled: zod.boolean().optional(),
    IsBdcTermsAccepted: zod.coerce.boolean().refine((bool) => bool == true, {
      message: "Please Accept Membership Terms and Conditions",
    }),
    IsTermsAccepted: zod.coerce.boolean().refine((bool) => bool == true, {
      message: "Please Accept Terms and Conditions",
    }),
  })
  .refine(
    (values) => {
      return values.password === values.confirmPassword;
    },
    {
      message: "Passwords must match!",
      path: ["confirmPassword"],
    }
  );

export const UpdateDetailsAPISchemas = zod.object({
  token: zod.string(),
  emailAddress: zod.string(),
  password: zod.string().optional(),
  firstName: zod.string(),
  lastName: zod.string(),
  mobileNumber: zod.string(),
  // birthday: zod.string().optional(),
  flEmail: zod.number().optional(),
  flSMS: zod.number().optional(),
  preferredStore: zod.string().optional(),
});

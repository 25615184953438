import { http } from "../utils";
import { UpdateDetailsAPITypes } from "../types/updateDetails";
import { MemberDetailsAPISchemas } from "../schemas/members";
import { MemberDetailsAPISchemasTypes } from "../types/members";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const MODULECODE = process.env.REACT_APP_MODULECODE;

export async function getMemberDetailAPI({
  token,
}: {
  token: string;
}): Promise<MemberDetailsAPISchemasTypes> {
  try {
    const data = {
      token: token.toUpperCase(),
    };
    const headers = { moduleCode: MODULECODE };
    const url = `${BASE_URL}/loyalty/posSignupEnquiry`;
    const response = await http.post<unknown>(url, data, {
      headers,
    });
    const parsed = MemberDetailsAPISchemas.safeParse(response.data);
    if (!parsed.success) {
      throw {
        message: "schema ERROR - member - GET",
        error: JSON.stringify(parsed.error),
      };
    }
    return parsed.data;
  } catch (error) {
    throw error;
  }
}

export async function updateMemberDetailAPI({
  token,
  emailAddress,
  password,
  firstName,
  lastName,
  mobileNumber,
  // birthday,
  flEmail,
  flSMS,
  preferredStore,
}: UpdateDetailsAPITypes): Promise<void> {
  const url = `${BASE_URL}/loyalty/posSignupUpdate`;
  // const birthDate = birthday ? getDate(birthday) : "";
  const data = {
    token: token.toUpperCase(),
    emailAddress,
    password,
    firstName,
    lastName,
    mobileNumber,
    // birthday,
    flEmail,
    flSMS,
    preferredStore,
  };
  const headers = { moduleCode: MODULECODE };
  return await http.post(url, data, {
    headers,
  });
}

const getDate = (birthday: string) => {
  const date = new Date(birthday);
  const day =
    date.getDate() < 10 ? `0${date.getDate()}` : String(date.getDate());
  const month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : String(date.getMonth() + 1);
  // const year = String(date.getFullYear());
  const year = "1904";
  return `${day}-${month}-${year}`;
};

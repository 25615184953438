import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

import { getMemberDetailAPI } from "../../api/members";

export const useMemberDetails = (token?: string | null) => {
  const [isAlreadyRegistered, isAlreadyRegisteredSet] =
    useState<boolean>(false);
  const {
    data: dataMemberDetails,
    isLoading: isLoadingMemberDetails,
    isError: isErrorMemberDetails,
    isSuccess: isSuccessMemberDetails,
    error: ErrorMemberDetails,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["useMemberDetails", token],
    queryFn: async () => {
      try {
        if (token) {
          return await getMemberDetailAPI({ token });
        }
      } catch (e) {
        const x: { description: string; response: number } = JSON.parse(
          JSON.stringify(e)
        );
        if (
          ["Token is already used", "Token expired"].includes(x.description)
        ) {
          isAlreadyRegisteredSet(true);
        }
        throw e;
      }
    },
  });

  return {
    dataMemberDetails,
    isLoadingMemberDetails,
    isErrorMemberDetails,
    isSuccessMemberDetails,
    ErrorMemberDetails,
    isAlreadyRegistered,
  };
};

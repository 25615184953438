import React, { useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "./Form.css";
import { useMemberDetails } from "./useMemberDetails";
import { useUpdateDetails } from "./useUpdateDetails";
import { useMemberDetailsUpdateForm } from "./useUpdateDetailsForm";

import ppLogo from "../../assets/pp-logo-without-background.png";

const REGION = [
  {
    id: 1,
    name: "Northland",
    store: [
      {
        id: 20516151,
        name: "Paper Plus Kerikeri",
      },
      {
        id: 20516147,
        name: "Paper Plus Whangarei",
      },
    ],
  },
  {
    id: 2,
    name: "Auckland",
    store: [
      {
        id: 20516284,
        name: "Paper Plus Auckland Hospital",
      },
      {
        id: 20516156,
        name: "Paper Plus Birkenhead",
      },
      {
        id: 20516157,
        name: "Paper Plus Browns Bay",
      },
      {
        id: 113871109,
        name: "Paper Plus Devonport",
      },
      {
        id: 20516158,
        name: "Paper Plus Eastridge",
      },
      {
        id: 116077447,
        name: "Paper Plus Glenfield Mall",
      },
      {
        id: 20516256,
        name: "Paper Plus Howick",
      },
      {
        id: 20516162,
        name: "Paper Plus Meadowbank",
      },
      {
        id: 20516152,
        name: "Paper Plus Orewa",
      },
      {
        id: 20516164,
        name: "Paper Plus Parnell",
      },
      {
        id: 20516218,
        name: "Paper Plus Ponsonby",
      },
      {
        id: 20516277,
        name: "Paper Plus Pukekohe",
      },
      {
        id: 20516219,
        name: "Paper Plus Remuera",
      },
      {
        id: 108567293,
        name: "Paper Plus Takapuna",
      },
      {
        id: 20516283,
        name: "Paper Plus Warkworth",
      },
      {
        id: 108552064,
        name: "Paper Plus West City",
      },
      {
        id: 111704531,
        name: "Paper Plus Whangaparaoa",
      },
      {
        id: 20516186,
        name: "Paper Plus Helensville",
      },
      {
        id: 20516183,
        name: "Paper Plus Highland Park",
      },
      {
        id: 20516221,
        name: "Paper Plus Sunnynook",
      },
      {
        id: 20516300,
        name: "Paper Plus Waiheke",
      },
      {
        id: 20516321,
        name: "Paper Plus Waiuku",
      },
    ],
  },
  {
    id: 3,
    name: "Waikato",
    store: [
      {
        id: 103023633,
        name: "Paper Plus Cambridge",
      },
      {
        id: 20516287,
        name: "Paper Plus Hamilton",
      },
      {
        id: 20516228,
        name: "Paper Plus Matamata",
      },
      {
        id: 20516271,
        name: "Paper Plus Morrinsville",
      },
      {
        id: 20516237,
        name: "Paper Plus Te Awamutu",
      },
      {
        id: 20516235,
        name: "Paper Plus Taupo",
      },
    ],
  },
  {
    id: 4,
    name: "Thames/Coromandel",
    store: [
      {
        id: 20516301,
        name: "Paper Plus Waihi",
      },
      {
        id: 20516311,
        name: "Paper Plus Whitianga",
      },
    ],
  },
  {
    id: 5,
    name: "Bay of Plenty",
    store: [
      {
        id: 20516330,
        name: "Paper Plus Bethlehem",
      },
      {
        id: 20516193,
        name: "Paper Plus Katikati",
      },
      {
        id: 20516200,
        name: "Paper Plus Mt Maunganui",
      },
      {
        id: 20516231,
        name: "Paper Plus Opotiki",
      },
      {
        id: 20516208,
        name: "Paper Plus Papamoa",
      },
      {
        id: 20516279,
        name: "Paper Plus Rotorua Central",
      },
      {
        id: 20516254,
        name: "Paper Plus Te Puke",
      },
      {
        id: 20516258,
        name: "Paper Plus Whakatane",
      },
    ],
  },
  {
    id: 6,
    name: "King Country",
    store: [
      {
        id: 20516261,
        name: "Paper Plus Otorohanga",
      },
      {
        id: 20516217,
        name: "Paper Plus Te Kuiti",
      },
      {
        id: 20516320,
        name: "Paper Plus Taumarunui",
      },
    ],
  },
  {
    id: 7,
    name: "Gisborne/Hawkes Bay",
    store: [
      {
        id: 20516292,
        name: "Paper Plus Gisborne",
      },
      {
        id: 20516255,
        name: "Paper Plus Hastings",
      },
      {
        id: 20516234,
        name: "Paper Plus Taradale",
      },
      {
        id: 20516257,
        name: "Paper Plus Waipukurau",
      },
      {
        id: 20516185,
        name: "Paper Plus Havelock North",
      },
    ],
  },
  {
    id: 8,
    name: "Taranaki",
    store: [
      {
        id: 20516227,
        name: "Paper Plus Hawera",
      },
      {
        id: 20516233,
        name: "Paper Plus Stratford",
      },
    ],
  },
  {
    id: 9,
    name: "Manawatu",
    store: [
      {
        id: 20516225,
        name: "Paper Plus Feilding",
      },
      {
        id: 20516333,
        name: "Paper Plus Palmerston North",
      },
      {
        id: 20516290,
        name: "Paper Plus Wanganui",
      },
      {
        id: 20516215,
        name: "Paper Plus Taihape",
      },
    ],
  },
  {
    id: 10,
    name: "Wairarapa",
    store: [
      {
        id: 20516139,
        name: "Paper Plus Carterton",
      },
      {
        id: 20516289,
        name: "Paper Plus Masterton",
      },
    ],
  },
  {
    id: 11,
    name: "Wellington",
    store: [
      {
        id: 20516240,
        name: "Paper Plus Coastlands",
      },
      {
        id: 20516192,
        name: "Paper Plus Karori",
      },
      {
        id: 20516242,
        name: "Paper Plus Kilbirnie",
      },
      {
        id: 20516244,
        name: "Paper Plus Levin",
      },
      {
        id: 20516245,
        name: "Paper Plus Lower Hutt",
      },
      {
        id: 20516260,
        name: "Paper Plus North City",
      },
      {
        id: 20516209,
        name: "Paper Plus Petone",
      },
      {
        id: 20516246,
        name: "Paper Plus Upper Hutt",
      },
    ],
  },
  {
    id: 12,
    name: "Tasman/Nelson",
    store: [
      {
        id: 20516148,
        name: "Paper Plus Blenheim",
      },
      {
        id: 20516154,
        name: "Paper Plus Motueka",
      },
      {
        id: 20516317,
        name: "Paper Plus Nelson",
      },
      {
        id: 20516291,
        name: "Paper Plus Richmond",
      },
      {
        id: 20516216,
        name: "Paper Plus Takaka",
      },
    ],
  },
  {
    id: 13,
    name: "Canterbury",
    store: [
      {
        id: 20516323,
        name: "Paper Plus Ashburton",
      },
      {
        id: 20516137,
        name: "Paper Plus Barrington",
      },
      {
        id: 20516247,
        name: "Paper Plus Ferrymead",
      },
      {
        id: 20516170,
        name: "Paper Plus Hornby",
      },
      {
        id: 20516190,
        name: "Paper Plus Kaiapoi",
      },
      {
        id: 20516191,
        name: "Paper Plus Kaikoura",
      },
      {
        id: 20516294,
        name: "Paper Plus New Brighton",
      },
    ],
  },
  {
    id: 14,
    name: "Otago",
    store: [
      {
        id: 20516173,
        name: "Paper Plus Alexandra",
      },
      {
        id: 20516324,
        name: "Paper Plus Cromwell",
      },
      {
        id: 20516175,
        name: "Paper Plus Dunedin",
      },
      {
        id: 20516332,
        name: "Paper Plus Mosgiel",
      },
      {
        id: 20516178,
        name: "Paper Plus Oamaru",
      },
      {
        id: 20516138,
        name: "Paper Plus Queenstown Airport",
      },
      {
        id: 20516181,
        name: "Paper Plus Wanaka",
      },
    ],
  },
  {
    id: 15,
    name: "West Coast",
    store: [
      {
        id: 20516248,
        name: "Paper Plus Kaikoura",
      },
      {
        id: 20516187,
        name: "Paper Plus Kaikoura",
      },
    ],
  },
  {
    id: 16,
    name: "Southland",
    store: [
      {
        id: 20516176,
        name: "Paper Plus Gore",
      },
      {
        id: 20516177,
        name: "Paper Plus Invercargill",
      },
      {
        id: 20516184,
        name: "Paper Plus Te Anau",
      },
      {
        id: 20516182,
        name: "Paper Plus Winton",
      },
    ],
  },
  {
    id: 17,
    name: "Webstore",
    store: [
      {
        id: 19964730,
        name: "Paper Plus Webstore",
      },
    ],
  },
];

ReactGA.initialize([
  {
    // UAT - YEJOO
    trackingId: "G-VZ8SG98ZBX",
  },
  {
    // PROD - KIKI
    trackingId: "G-1JPHVH9X7P",
  },
]);

function useUrlQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface Store {
  id: number;
  name: string;
}

const Index: React.FC = () => {
  const query = useUrlQuery();
  let data = query.get("data");
  let token = query.get("token");
  let ident = query.get("ident");
  let identType = query.get("identType");

  // IF DATA PRESENT AS PARAMS THEN DECODE
  // ASSUMING DATA IS BASE 64
  if (data) {
    const decodedData = atob(data);
    const urlParams = new URLSearchParams(decodedData);
    token = urlParams.get("token");
    ident = urlParams.get("ident");
    identType = urlParams.get("identType");
  }

  const [togglePassword, togglePasswordSet] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<string>("");
  const [stores, setStores] = useState<Store[]>([]);
  const [serverError, serverErrorSet] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  // MEMBER INQUIRY
  const {
    dataMemberDetails,
    isLoadingMemberDetails,
    isSuccessMemberDetails,
    isErrorMemberDetails,
    ErrorMemberDetails,
    isAlreadyRegistered,
  } = useMemberDetails(token || "");

  const { handleSubmitUpdateDetails, isSuccess: isSuccessUpdateDetails } =
    useUpdateDetails(token || "", serverErrorSet);

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const regionId = event.target.value;
    setSelectedRegion(regionId);
    const region = REGION.find((reg) => reg.id === parseInt(regionId));
    setStores(region ? region.store : []);
  };

  const {
    register,
    control,
    handleOnSubmit,
    setValue,
    watch,
    reset,
    errors,
    isReadOnlyEmailAddress,
    isReadOnlyMobileNumber,
  } = useMemberDetailsUpdateForm({
    serverError,
    token: token || "",
    dataMemberDetails,
    onSubmit: handleSubmitUpdateDetails,
  });

  useEffect(() => {
    if (!isSuccessMemberDetails && !isErrorMemberDetails) {
      ReactGA.send({
        hitType: "pageview",
        page: "/signup",
        title: "Paperplus - Sign up",
      });
    }
    if (isSuccessMemberDetails) {
      ReactGA.send({
        hitType: "pageview",
        page: "/signup",
        title: "Paperplus - Member Found - Success",
      });
    }
    if (isErrorMemberDetails) {
      ReactGA.send({
        hitType: "pageview",
        page: "/signup",
        title: "Paperplus - Member Not Found - Error",
      });
    }
  }, [isSuccessMemberDetails, isErrorMemberDetails]);

  return (
    <div>
      <form onSubmit={handleOnSubmit} className="font-sans">
        <div className="header-container">
          <h2 className="font-extrabold tracking-wider">Sign Up</h2>
          <img src={ppLogo} alt="Paper Plus Logo" className="header-logo" />
        </div>
        {isLoadingMemberDetails ? (
          <div className="p-8 bg-gray-100 mt-6 my-4 rounded-xl">
            <label className="font-light">Loading member details ...</label>
          </div>
        ) : null}

        {isErrorMemberDetails ? (
          <>
            {!isAlreadyRegistered ? (
              <div className="p-8 bg-gray-100 mt-6 my-4 rounded-xl">
                <label className="font-light">
                  Sorry, Something went wrong. Please check with Paperplus
                  support.
                </label>
                <br />
                <br />
                <label className="font-light">
                  <a
                    href="https://www.paperplus.co.nz"
                    target="_blank"
                    className="font-light"
                  >
                    Contact Support
                  </a>
                </label>
              </div>
            ) : null}

            {isAlreadyRegistered ? (
              <div className="p-8 bg-gray-100 mt-6 my-4 rounded-xl">
                <label className="font-light">
                  Sorry, account is already registered.
                </label>
                <br />
                <br />
                <label className="font-light">
                  <a
                    href="https://www.paperplus.co.nz/contact"
                    target="_blank"
                    className="font-light"
                  >
                    Paperplus Homepage
                  </a>
                </label>
              </div>
            ) : null}
          </>
        ) : null}

        {isSuccessUpdateDetails ? (
          <div className="p-8 bg-gray-100 mt-6 my-4 rounded-xl">
            <label className="font-light">
              Nice! Your account is now registered.
            </label>
          </div>
        ) : null}

        {!isLoadingMemberDetails && isSuccessMemberDetails ? (
          <>
            <label>First name * </label>
            <input
              type="text"
              {...register("firstName", { pattern: /^[A-Za-z]+$/i })}
            />
            {errors.firstName && <p>{errors.firstName.message}</p>}
            <label>Last Name * </label>
            <input
              type="text"
              {...register("lastName", { pattern: /^[A-Za-z]+$/i })}
            />
            {errors.lastName && <p>{errors.lastName.message}</p>}
            <label>Mobile Number {isReadOnlyMobileNumber ? `` : `*`} </label>
            <input
              style={{
                backgroundColor: isReadOnlyMobileNumber ? "#bbbbbb" : "",
              }}
              readOnly={isReadOnlyMobileNumber}
              type="text"
              {...register("mobileNumber", { pattern: /^[0-9]+$/i })}
            />
            {errors.mobileNumber && <p>{errors.mobileNumber.message}</p>}
            <label>Email Address {isReadOnlyEmailAddress ? `` : `*`} </label>
            <input
              style={{
                backgroundColor: isReadOnlyEmailAddress ? "#bbbbbb" : "",
              }}
              readOnly={isReadOnlyEmailAddress}
              type="email"
              {...register("emailAddress")}
            />
            {errors.emailAddress && <p>{errors.emailAddress.message}</p>}
            <label>Region </label>
            <select {...register("region")} onChange={handleRegionChange}>
              <option value="">Please select</option>
              {REGION.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            {errors.region && <p>{errors.region.message}</p>}
            <label>Preferred Store </label>
            <select
              {...register("preferredStore", { pattern: /^[0-9]+$/i })}
              disabled={!selectedRegion}
              className={!selectedRegion ? "select-disabled" : "select-enabled"}
              style={{
                backgroundColor: !selectedRegion ? "lightgrey" : "white",
              }}
            >
              <option value="">
                {!selectedRegion
                  ? "Please select Region first"
                  : "Please select"}
              </option>
              {stores.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.name}
                </option>
              ))}
            </select>
            {errors.preferredStore && <p>{errors.preferredStore.message}</p>}
            {/* <div className="input-group">
        <div className="input-field">
          <label>Birthday</label>
          <select {...register("birthdayDD", { pattern: /^[0-9]+$/i })}>
            <option value="">DD</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
          </select>{" "}
        </div>
        <div className="input-field">
          <label>&nbsp;</label>
          <select {...register("birthdayMM", { pattern: /^[0-9]+$/i })}>
            <option value="">MM</option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>{" "}
        </div>
      </div> */}
            {/* {errors.birthdayDD && <p>{errors.birthdayDD.message}</p>}
      {errors.birthdayMM && <p>{errors.birthdayMM.message}</p>} */}
            <div className="text-sm mt-6">
              <b>Earn & redeem Paper Plus Rewards when shopping online.</b>
            </div>
            <div className="w-full">
              <div className="flex justify-between w-full">
                <label>Password </label>
                <label
                  className="font-extralight text-xs"
                  onClick={() => togglePasswordSet(!togglePassword)}
                >
                  {togglePassword ? `Hide Password` : `Show Password`}
                </label>
              </div>
              <input
                className="w-full"
                type={togglePassword ? "text" : "password"}
                {...register("password")}
              />
              {errors.password && <p>{errors.password.message}</p>}
            </div>
            <label>Confirm Password </label>
            <input
              type={togglePassword ? "text" : "password"}
              {...register("confirmPassword")}
            />
            {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
            <div className="form-group">
              <div className="checkbox">
                <div className="Test checkbox">
                  <input
                    type="checkbox"
                    id="IsNewsUpdatesEmailsEnabled"
                    {...register("IsNewsUpdatesEmailsEnabled")}
                  />
                  <span>
                    Yes, I am happy to receive special offers, news &amp;
                    reviews through email alerts
                  </span>
                </div>
                <div className="Test checkbox">
                  <input
                    type="checkbox"
                    id="IsTextEnabled"
                    {...register("IsTextEnabled")}
                  />
                  <span>Yes, I am happy to receive SMS/text alerts</span>
                </div>
                <div className="px-4 py-2 pb-4 bg-gray-100 mt-6 my-4 rounded-xl">
                  <div className="Test checkbox">
                    <input
                      type="checkbox"
                      value="true"
                      id="IsBdcTermsAccepted"
                      {...register("IsBdcTermsAccepted")}
                    />
                    <span>
                      I Accept the&nbsp;
                      <a
                        target="new"
                        href="https://www.paperplus.co.nz/bdc-terms-and-conditions"
                      >
                        Terms &amp; Conditions of Membership
                      </a>
                      *
                    </span>
                  </div>
                  {errors.IsBdcTermsAccepted && (
                    <p className="font-semibold pl-6">
                      {errors.IsBdcTermsAccepted.message}
                    </p>
                  )}
                  <div className="Test checkbox">
                    <input
                      type="checkbox"
                      value="true"
                      id="IsTermsAccepted"
                      {...register("IsTermsAccepted")}
                    />
                    <span>
                      I Accept the&nbsp;
                      <a
                        target="new"
                        href="https://www.paperplus.co.nz/Terms-and-Conditions.aspx"
                      >
                        Terms and Conditions of this website
                      </a>
                      *
                    </span>
                  </div>
                  {errors.IsTermsAccepted && (
                    <p className="font-semibold pl-6">
                      {errors.IsTermsAccepted.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <input
              type="submit"
              disabled={isSuccessUpdateDetails ? true : false}
              value="SIGN ME UP!"
            />
          </>
        ) : null}
      </form>
    </div>
  );
};

export default Index;

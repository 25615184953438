import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { UpdateDetailsFormSchemas } from "../../schemas/UpdateDetailsSchemas";
import { UpdateDetailsFormTypes } from "../../types/updateDetails";
import { MemberDetailsAPISchemasTypes } from "../../types/members";

export const useMemberDetailsUpdateForm = ({
  serverError,
  token,
  dataMemberDetails,
  onSubmit,
}: {
  serverError: {
    label: string;
    value: string;
  }[];
  token: string;
  dataMemberDetails: MemberDetailsAPISchemasTypes | undefined;
  onSubmit: (updatedData: UpdateDetailsFormTypes) => void;
}) => {
  const defaultValues = {
    token: "",
    emailAddress: "",
    mobileNumber: "",
    password: "",
    firstName: "",
    lastName: "",
    // birthday: "",
    region: "",
    preferredStore: "",
    IsNewsUpdatesEmailsEnabled: false,
    IsTextEnabled: false,
    IsBdcTermsAccepted: false,
    IsTermsAccepted: false,
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    setValue,
    getValues,
    watch,
    setError,
  } = useForm<UpdateDetailsFormTypes>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: zodResolver(UpdateDetailsFormSchemas),
    defaultValues,
    shouldFocusError: false,
  });

  const handleOnSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  useEffect(() => {
    if (dataMemberDetails) {
      const firstName = dataMemberDetails?.traderDetails.firstName || "";
      const lastName = dataMemberDetails?.traderDetails.lastName || "";
      const isValidFirstName = [firstName.toLowerCase()].includes("cardholder")
        ? false
        : true;
      const isValidLastName = isNaN(Number(lastName));
      console.log({ isValidFirstName, isValidLastName });
      reset({
        token,
        firstName: isValidFirstName ? firstName : "",
        lastName: isValidLastName ? lastName : "",
        emailAddress: dataMemberDetails?.traderDetails.emailAddress || "",
        mobileNumber: dataMemberDetails?.traderDetails.mobileNumber || "",
      });
      // if (!isValidFirstName) {
      //   setError("firstName", {
      //     type: "manual",
      //     message: `First name is required`,
      //   });
      // }
      // if (!isValidLastName) {
      //   setError("lastName", {
      //     type: "manual",
      //     message: `Last name is required`,
      //   });
      // }
    }
  }, [token, dataMemberDetails, reset, setError]);

  useEffect(() => {
    if (serverError.length) {
      serverError.map((item) =>
        setError(item.value as "emailAddress" | "mobileNumber", {
          type: "manual",
          message: `This ${item.label} is already taken`,
        })
      );
    }
  }, [serverError, setError]);

  const isReadOnlyEmailAddress = useMemo(() => {
    return dataMemberDetails?.traderDetails.emailAddress ? true : false;
  }, [dataMemberDetails]);

  const isReadOnlyMobileNumber = useMemo(() => {
    return dataMemberDetails?.traderDetails.mobileNumber ? true : false;
  }, [dataMemberDetails]);

  return {
    register,
    handleOnSubmit,
    control,
    errors,
    setValue,
    reset,
    watch,
    isReadOnlyEmailAddress,
    isReadOnlyMobileNumber,
  };
};

import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "./views/UpdateDetails";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Form
            onClose={function (): void {
              throw new Error("Something went wrong.");
            }}
          />
        </div>
      </QueryClientProvider>
      <ToastContainer position="bottom-center" autoClose={3_000} />
    </Router>
  );
}

export default App;

/* 
  
*/
